import Alpine from "alpinejs";
import toastr from "toastr/toastr";
import jQuery from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import { Fancybox } from "@fancyapps/ui";

window.$ = jQuery;

window.Alpine = Alpine;

Alpine.start();

window.toastr = toastr;
toastr.options.closeButton = true;
toastr.options.positionClass = "toast-bottom-center";
toastr.options.preventDuplicates = true;
toastr.options.onclick = null;
toastr.options.showDuration = "300";
toastr.options.hideDuration = "1000";
toastr.options.timeOut = "6000";
toastr.options.extendedTimeOut = "1000";
toastr.options.showEasing = "swing";
toastr.options.hideEasing = "linear";
toastr.options.showMethod = "slideDown";
toastr.options.hideMethod = "slideUp";

Fancybox.bind("[data-fancybox]", {
    buttons: ["slideShow", "zoom", "fullScreen", "share", "close"],
    loop: true,
    protect: true,
    Thumbs: {
        type: "classic",
        Carousel: {
            dragFree: false,
            slidesPerPage: "auto",
            Navigation: true,

            axis: "y",
            // breakpoints: {
            //   '(min-width: 768px)': {
            //     axis: 'y',
            //   },
            // },
        },
    },
    thumbs: true,
});

if ($(".banner-slider") && $(".banner-slider").length > 0) {
    new Splide(".banner-slider", {
        type: "loop",
        perPage: 1,
        perMove: 1,
        height: "100vh",
        pagination: false,
        arrows: true,
        autoplay: true,
        interval: 4000,
        speed: 2000,
        breakpoints: {
            1024: {
                height: "80vh",
            },
            576: {
                height: "92vh",
            },
        },
    }).mount();
}

if ($(".fresh-arrival-slider") && $(".fresh-arrival-slider").length > 0) {
    const arrivalSlider = new Splide(".fresh-arrival-slider", {
        type: "slide",
        perPage: 4,
        perMove: 1,
        pagination: false,
        arrows: false,
        gap: 30,
        // padding: "17%",
        breakpoints: {
            1600: {
                // padding: "7.5%",
            },
            1500: {
                // padding: "6%",
            },
            1450: {
                // padding: "5%",
            },
            1399: {
                // padding: "9%",
            },
            1350: {
                // padding: "7%",
            },
            1300: {
                // padding: "6%",
            },
            1250: {
                // padding: "4%",
            },
            1199: {
                // padding: "10%",
            },
            1150: {
                // padding: "7.5%",
            },
            1100: {
                // padding: "6%",
            },
            1050: {
                // padding: "3.5%",
            },
            1000: {
                // padding: "3%",
            },
            992: {
                perPage: 2,
                // padding: "3%",
            },
            576: {
                perPage: 2,
                gap: 20,
            },
        },
    }).mount();

    $(".fresh-arrivals .custom-arrows-left").click(function () {
        arrivalSlider.go("<");
    });

    $(".fresh-arrivals .custom-arrows-right").click(function () {
        arrivalSlider.go(">");
    });
}

if ($(".special-product-slider") && $(".special-product-slider").length > 0) {
    new Splide(".special-product-slider", {
        type: "loop",
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: true,
        speed: 1500,
        gap: "3px",
        direction: "ttb",
        height: "90vh",
        autoplay: true,
        breakpoints: {
            576: {
                direction: "ltr",
            },
        },
    }).mount();
}

if ($(".collection-slider") && $(".collection-slider").length > 0) {
    if ($(".collection-slider").data("count") < 5) {
        const collectionSlider = new Splide(".collection-slider", {
            perPage: 4,
            perMove: 1,
            arrows: false,
            pagination: false,
            gap: "5px",
            padding: "26rem",
            autoScroll: {
                pauseOnHover: false,
            },
            breakpoints: {
                1900: {
                    perPage: 3,
                    padding: "17rem",
                },
                1648: {
                    perPage: 3,
                    padding: "11rem",
                },
                1248: {
                    perPage: 3,
                    padding: "4rem",
                },
                992: {
                    perPage: 2,
                    padding: "4rem",
                },
                576: {
                    perPage: 1,
                    padding: "2rem",
                },
            },
        }).mount();
    } else {
        const collectionSlider = new Splide(".collection-slider", {
            type: "loop",
            perPage: 5,
            perMove: 1,
            arrows: false,
            pagination: false,
            gap: "5px",
            autoScroll: {
                pauseOnHover: false,
            },
            breakpoints: {
                1900: {
                    perPage: 3,
                    padding: "17rem",
                },
                1648: {
                    perPage: 3,
                    padding: "11rem",
                },
                1248: {
                    perPage: 3,
                    padding: "4rem",
                },
                992: {
                    perPage: 2,
                    padding: "4rem",
                },
                576: {
                    perPage: 1,
                    padding: "2rem",
                },
            },
        }).mount(window.splide.Extensions);
    }
}

if ($("#product-page-slider") && $("#product-page-slider").length > 0) {
    var main = new Splide("#product-page-slider", {
        type: "fade",
        rewind: true,
        pagination: false,
        arrows: false,
        fixedHeight: 300,
        breakpoints: {
            992: {
                fixedHeight: 700,
            },
            740: {
                fixedHeight: 600,
            },
            576: {
                fixedHeight: 400,
            },
            320: {
                fixedHeight: 300,
            },
        },
    });

    var thumbnails = new Splide("#product-page-thumbs-slider", {
        fixedWidth: 100,
        fixedHeight: 150,
        gap: 12,
        rewind: true,
        pagination: false,
        cover: true,
        isNavigation: true,
        breakpoints: {
            600: {
                fixedWidth: 60,
                fixedHeight: 80,
            },
        },
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
}

$(document).ready(function () {
    // Footer tabs
    $(".footer-tab-btn").on("click", function () {
        for (let i = 0; i < $(".footer-tab-btn").length; i++) {
            $($($(".footer-tab-btn")[i]).children()[0]).text("+");
        }
        $($(this).children()[0]).text("-");
    });

    if (window.app_url + "/" == window.location.href.split("?")[0] || window.app_url + "/shop" == window.location.href.split("?")[0]) {
        if ($(window).scrollTop() > 100) {
            $("header").addClass("_scroll");
        } else {
            $("header").removeClass("_scroll");
        }

        $(window).scroll(function (event) {
            if ($(window).scrollTop() > 100) {
                $("header").addClass("_scroll");
            } else {
                $("header").removeClass("_scroll");
            }
        });
    } else {
        $("header").addClass("_scroll");
    }

    $(".wishlist").click(function () {
        // console.log($(this).find('svg').css())
    });
});

if ($("[data-page=homepage]")) {
    $(".variation").each(function () {
        $(this).css("background-color", $(this).data("color"));
    });
}

if ($(".tooltip")) {
    $(this).each(function () {
        $(this).html($(this).data("content"));
    });
}

function toggleSidebar(target = ".offcanvas") {
    $(target).toggleClass("show");
    $(".offcanvas-backdrop").toggleClass("active");
}

$(".menu-toggle-btn").click(function () {
    toggleSidebar("#sidebar");
});
$(".offcanvas-backdrop").click(function () {
    $(".offcanvas").removeClass("show");
    $(".offcanvas-backdrop").removeClass("active");
});
$(".offcanvasCloseBtn").click(function () {
    $(".offcanvas").removeClass("show");
    $(".offcanvas-backdrop").removeClass("active");
});
$(".searchbar-toggle").click(function () {
    toggleSidebar("#searchbar-wrap");
});


$(".wishlist").click(function () {
    $(this).toggleClass("active");
});

$("[data-bg-img]").css(
    "background-image",
    `url("${$("[data-bg-img]").data("bg-img")}")`
);

//   let count = 1;
//   $(".quantity-selector .output").html(count < 10 ? `0${count}` : count);
//   function quantitySelector() {
//     $(".quantity-selector .minus").click(function () {
//       if (count > 1) {
//         count--;
//       }
//       $(".quantity-selector .output").html(count < 10 ? `0${count}` : count);
//     });
//     $(".quantity-selector .plus").click(function () {
//       count++;
//       $(".quantity-selector .output").html(count < 10 ? `0${count}` : count);
//     });
//   }
//   quantitySelector();

function accordion() {
    var Accordion = function (el, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        // Variables privadas
        var links = this.el.find(".js-accordion-link");
        // Evento
        links.on(
            "click",
            { el: this.el, multiple: this.multiple },
            this.dropdown
        );
    };

    Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el,
            $this = $(this),
            $next = $this.next();

        $next.slideToggle();
        $this.parent().toggleClass("is-open");

        if (!e.data.multiple) {
            $el.find(".js-accordion-submenu")
                .not($next)
                .slideUp()
                .parent()
                .removeClass("is-open");
            $el.find(".js-accordion-submenu").not($next).slideUp();
        }
    };
    var accordion = new Accordion($("#accordion"), false);
}
accordion();

var modal = document.querySelector(".modal");
var trigger = document.querySelector(".trigger");
var closeButton = document.querySelector(".close-button");

function toggleModal() {
    modal.classList.toggle("show-modal");
}

function windowOnClick(event) {
    if (event.target === modal) {
        toggleModal();
    }
}

$("#apply-coupon-btn").click(function () {
    $("#discountModal").addClass("show-modal");
});

$("#discountModalClose").click(function () {
    $("#discountModal").removeClass("show-modal");
});

$("#delete-account-btn").click(function () {
    $("#deleteAccountModal").addClass("show-modal");
});

$("#deleteAccountClose").click(function () {
    $("#deleteAccountModal").removeClass("show-modal");
});

$("#need-support-btn").click(function () {
    $("#raiseSupportTicket").addClass("show-modal");
});

$("#supportClose").click(function () {
    $("#raiseSupportTicket").removeClass("show-modal");
});

$("#loginBtn").click(function () {
    $("#loginModal").addClass("show-modal");
});

$("#loginClose").click(function () {
    $("#loginModal").removeClass("show-modal");
});

$("#sizeNavBtn").click(function () {
    $("#sizeDropdown").toggleClass("active");
    $("#categoryDropdown").removeClass("active");
    $("#priceDropdown").removeClass("active");
    $("#sortByDropdown").removeClass("active");
    $(".backdrop").addClass("active");
});
$("#categoryNavBtn").click(function () {
    $("#sizeDropdown").removeClass("active");
    $("#categoryDropdown").toggleClass("active");
    $("#priceDropdown").removeClass("active");
    $("#sortByDropdown").removeClass("active");
    $(".backdrop").addClass("active");
});
$("#priceNavBtn").click(function () {
    $("#sizeDropdown").removeClass("active");
    $("#categoryDropdown").toggleClass("active");
    $("#priceDropdown").toggleClass("active");
    $("#sortByDropdown").removeClass("active");
    $(".backdrop").addClass("active");
});
$("#sortByBtn").click(function () {
    $("#sizeDropdown").removeClass("active");
    $("#categoryDropdown").removeClass("active");
    $("#priceDropdown").removeClass("active");
    $("#sortByDropdown").addClass("active");
    $(".backdrop").addClass("active");
});
$("#sortNavToggle").click(function () {
    $("#sizeDropdown").removeClass("active");
    $("#categoryDropdown").removeClass("active");
    $("#priceDropdown").removeClass("active");
    $("#sortByDropdown").addClass("active");
    $(".backdrop").addClass("active");
});
$(".backdrop").click(function () {
    $(".filter-dropdown").removeClass("active");
    $(".backdrop").removeClass("active");
    $("#sortByDropdown").removeClass("active");
});

$("#filterNavToggle").click(function () {
    $("#filters-wrap").addClass("show");
});

$(".accordion-header").each(function (index) {
    $(this).on("click", function () {
        var accordionContent = $(this).next(".accordion-content");

        accordionContent.toggleClass("active");

        $(".accordion-content").not(accordionContent).removeClass("active");
    });
});

//   if($('[data-page=product-details]')){
//     $('[data-fancybox="video-box"]').fancybox({
//       buttons : [
//         'slideShow',
//         'share',
//         'zoom',
//         'fullScreen',
//         'close',
//         'thumbs'
//       ],
//       thumbs : true,
//     });
//   }

// trigger.addEventListener("click", toggleModal);
// closeButton.addEventListener("click", toggleModal);
// window.addEventListener("click", windowOnClick);

$(".variation-options").on("change", function (event) {
    const selected = document.querySelectorAll(
        ".variation-options option:checked"
    );

    const values = Array.from(selected).map((el) => {
        let key = $($(el).parent()).data("attribute");
        let val = $(el).val();
        return { [key]: val };
    });

    $.ajax({
        url:
            window.app_url +
            "/api/product-variation-data/" +
            window.product.slug,
        type: "GET",
        data: {
            variation: values,
        },
        success: function (response) {
            Livewire.emit("variationUpdated", response.id);
        },
        error: function (error) {
            Livewire.emit("variationUpdated", "");
        },
    });
});

Livewire.on("cartUpdated", function () {
    $(".variation-options").val("");
});

$("#subject").hide();
$("#report").hide();

$("#support_subject_id").on("change", function () {
    $("#subject").hide();
    if (
        $("#support_subject_id option:selected").text() == "Other Enquiry" ||
        $("#support_subject_id option:selected").text() == "Other Enquiries" ||
        $("#support_subject_id option:selected").text() == "other enquiry" ||
        $("#support_subject_id option:selected").text() == "other enquiries"
    ) {
        $("#subject").show();
    }

    if (
        $("#support_subject_id option:selected").text() == "Report Abuse" ||
        $("#support_subject_id option:selected").text() ==
            "Report A Shop Owner" ||
        $("#support_subject_id option:selected").text() ==
            "Report A Customer" ||
        $("#support_subject_id option:selected").text() == "Report An Admin" ||
        $("#support_subject_id option:selected").text() == "report abuse" ||
        $("#support_subject_id option:selected").text() ==
            "report a shop owner" ||
        $("#support_subject_id option:selected").text() ==
            "report a customer" ||
        $("#support_subject_id option:selected").text() == "report an admin"
    ) {
        $("#report").show();
    }
});

$("#login-form").on("submit", function (e) {
    e.preventDefault();
    let form = $(this);
    let button = $(this).find('button[type="submit"]');

    button.attr("disabled", true);
    $.ajax({
        url: window.app_url + "/signin",
        type: "POST",
        data: $(this).serialize(),
        success: function (response) {
            toastr.success("Welcome back. You have logged in successfully!");
            setTimeout(() => {
                location.reload();
            }, 3000);
        },
        error: function (error) {
            button.attr("disabled", false);
            $("#login-form .message").text(
                "These credentials do not match our records!"
            );
        },
    });
});

function selectRating(e) {
    e.preventDefault();
    debugger;
}
